<template lang="pug">
	.d-flex.justify-content-between.align-items-center.position-relative.header
		router-link(to="/")
			img.header__logo(v-if="!isMobile" src="/assets/img/current/logo-blue.svg")
			img.header__logo(v-if="isMobile" src="/assets/img/current/logo-small-blue.svg")

		//- locale-switch-simple.m-0
</template>

<script>
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';

export default {
    components: {
        LocaleSwitchSimple,
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.header {
    z-index: 1;
    padding: 30px 0;

    @include respond-below(sm) {
        padding: 12px 0;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -12px;
            right: -12px;
            height: 1px;
            background-color: var(--brand-bg-primary);
        }
    }

    &__logo {
        width: 130px;
        height: 32px;

        @include respond-below(sm) {
            width: 24px;
            height: 24px;
        }
    }
}
</style>
