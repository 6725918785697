<template lang="pug">
	.d-flex.flex-column.h-100.general
		Header
		router-view
		footer-payment(:underLogoText="isMobile ? currentYear : `2020 - ${currentYear}`" :rightText="$t('h1.serviceRules3')" :switches="false")
</template>

<script>
import Header from '@/containers/Header';
import FooterPayment from '@/containers/FooterPayment';

export default {
    components: {
        Header,
        FooterPayment,
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
    created() {
        this.viewGeoIpRussia();
    },
    beforeMount() {
        document.body.classList.add('background-main');
    },
    destroyed() {
        document.body.classList.remove('background-main');
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.general {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 32px;

    @include respond-below(md) {
        padding: 0 12px;
    }
}
</style>
